<template>
  <div style="display: flex; flex-direction: column; height: calc(100vh - 180px)">
    <div style="display: flex; flex-wrap: wrap">
      <div class="condition-item">
        <label class="fn-14">采集状态</label>
        <el-select
          style="width: 120px"
          clearable
          v-model="searchData.collectStatus"
          placeholder="采集状态"
          size="small"
        >
          <el-option
            v-for="item in collectStatusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            autocomplete="off"
            size="small"
          >
          </el-option>
        </el-select>
      </div>
      <div class="condition-item">
        <label class="fn-14">订单号</label>
        <el-input
          v-model="searchData.orderNo"
          style="width: 180px"
          placeholder="请输入订单号"
          autocomplete="off"
          clearable
          size="small"
        >
        </el-input>
      </div>
      <div class="condition-item" v-if="searchButton">
        <label class="fn-14">学校</label>
        <el-select
          clearable
          filterable
          v-model="searchData.schoolId"
          remote
          reserve-keyword
          placeholder="请输入学校名或代码检索"
          :remote-method="remoteMethod"
          :loading="loading"
          autocomplete="off"
          size="small"
        >
          <el-option
            v-for="item in schools"
            :key="item.id"
            :label="item.schoolFilterName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="condition-item">
        <label class="fn-14">采集名称</label>
        <el-input
          v-model="searchData.taskTitle"
          placeholder="请输入采集名称"
          autocomplete="off"
          size="small"
          clearable
          style="width: 130px"
        >
        </el-input>
      </div>
      <div class="condition-item">
        <label class="fn-14">支付金额</label>
        <el-input
          v-model="searchData.shotPrice"
          placeholder="请输入支付金额"
          autocomplete="off"
          size="small"
          clearable
          style="width: 130px"
        >
        </el-input>
      </div>
      <div class="condition-item" v-if="searchButton">
        <label class="fn-14">身份证号</label>
        <el-input
          style="width: 180px"
          v-model="searchData.idNumber"
          placeholder="身份证号"
          autocomplete="off"
          clearable
          size="small"
        ></el-input>
      </div>
      <div class="condition-item">
        <label class="fn-14">学生姓名</label>
        <el-input
          style="width: 130px"
          v-model="searchData.studentName"
          placeholder="请输入学生姓名"
          autocomplete="off"
          size="small"
          clearable
        ></el-input>
      </div>
      <div class="condition-item">
        <label class="fn-14">图片状态</label>
        <el-select
          style="width: 130px"
          clearable
          v-model="searchData.photoStatus"
          placeholder="图片状态"
          autocomplete="off"
          size="small"
        >
          <el-option
            v-for="item in photoStatusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="condition-item">
        <label class="fn-14">订单状态</label>
        <el-select
          style="width: 130px"
          clearable
          v-model="searchData.status"
          placeholder="订单状态"
          autocomplete="off"
          size="small"
        >
          <el-option
            v-for="item in statusList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="condition-item">
        <label class="fn-14">采集码</label>
        <el-input
          style="width: 130px"
          v-model="searchData.cjmn"
          placeholder="请输入采集码"
          autocomplete="off"
          size="small"
          clearable
        ></el-input>
      </div>
      <div class="condition-item">
        <label class="fn-14">学号</label>
        <el-input
          style="width: 130px"
          v-model="searchData.studentCode"
          placeholder="请输入学号"
          autocomplete="off"
          size="small"
          clearable
        ></el-input>
      </div>
      <div class="condition-item">
        <el-button
          v-if="searchButton"
          type="primary"
          icon="el-icon-search"
          @click="handleSearch"
          size="small"
          >查询
        </el-button>
        <el-button
          v-if="searchButton"
          type="info"
          icon="el-icon-refresh"
          @click="resetSearch"
          size="small"
          >重置
        </el-button>
      </div>
    </div>

    <div style="margin-bottom: 15px; flex: 1; overflow: hidden">
      <el-table
        :data="orderList"
        v-loading="tableLoading"
        stripe
        border
        height="100%"
        :row-style="changeRowBgColorByIsPay"
        :header-cell-style="{ background: '#f5f7fa', color: '#606266' }"
      >
        <template #empty>
          <p>{{ tableLoading == true ? "数据加载中" : "暂无数据" }}</p>
        </template>
        <el-table-column prop="orderNo" label="订单号" width="240"></el-table-column>
        <el-table-column prop="taskName" label="采集名称" width="240"> </el-table-column>
        <el-table-column prop="studentName" label="学生姓名" width="100" align="center">
          <template slot-scope="scope">
            <span>{{ execDecrypt(scope.row.studentName, "name") }}</span></template
          >
        </el-table-column>
        <!--                <el-table-column prop="taskDate" label="采集时间"></el-table-column>-->
        <el-table-column
          prop="createTime"
          :formatter="columnDateFormat"
          label="下单时间"
          width="240"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="payType" label="支付类型" width="120" align="center">
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.payType === 0"
              type="success"
              effect="plain"
              size="small"
              >线下支付</el-tag
            >
            <el-tag
              v-else-if="scope.row.payType === 1"
              type="success"
              effect="plain"
              size="small"
              >工行E支付
            </el-tag>
            <el-tag
              v-else-if="scope.row.payType === 2"
              type="success"
              effect="plain"
              size="small"
              >备用支付
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="shotPrice" label="金额" width="70" align="center">
        </el-table-column>
        <el-table-column
          prop="pathCollectedPhoto"
          label="采集状态"
          width="80"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.pathCollectedPhoto === null ? "未采集" : "已完成" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="pathCollectedPhoto"
          label="图片状态"
          width="80"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.pathCollectedPhoto === null ? "未上传" : "已上传" }}
          </template>
        </el-table-column>
        <el-table-column prop="status" label="订单状态" width="80" align="center">
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.status == 0"
              type="warning"
              effect="plain"
              size="small"
              >下单</el-tag
            >
            <el-tag
              v-else-if="scope.row.status == 1"
              type="success"
              effect="plain"
              size="small"
              >预付款
            </el-tag>
            <el-tag v-else-if="scope.row.status == 2" type="" effect="plain" size="small"
              >完成</el-tag
            >
            <el-tag
              v-else-if="scope.row.status == 3"
              type="danger"
              effect="plain"
              size="small"
              >取消</el-tag
            >
            <el-tag
              v-else-if="scope.row.status == 4"
              type="danger"
              effect="plain"
              size="small"
              >支付中</el-tag
            >
            <el-tag
              v-else-if="scope.row.status == 5"
              type="danger"
              effect="plain"
              size="small"
              >取消中</el-tag
            >
            <el-tag
              v-else-if="scope.row.status == 6"
              type="danger"
              effect="plain"
              size="small"
              >预取消</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column prop="asynReason" label="异步日志" width="280">
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="260" align="center">
          <template slot-scope="scope">
            <el-button v-if="viewButton" type="text" @click="handleView(scope.row.id)"
              >查看
            </el-button>
            <el-button
              v-if="uploadButton"
              type="text"
              v-show="scope.row.status === 1"
              @click="handleImport(scope.row.id)"
              >上传照片
            </el-button>
            <el-button
              v-if="reUploadButton"
              type="text"
              v-show="scope.row.status === 2"
              @click="handleReImport(scope.row.id, scope.row)"
              >重传照片
            </el-button>
            <el-button
              v-if="cencelButton"
              type="text"
              @click="handleCencelPay(scope.row.id)"
              >取消订单
            </el-button>
            <el-button
              v-if="orderFlowByorderNo"
              type="text"
              @click="handleOrderLogs(scope.row)"
            >
              订单支付日志
            </el-button>
            <el-button v-if="showOrderLogXp" type="text" @click="showOrderLog(scope.row)">
              订单日志
            </el-button>
            <el-button
              type="text"
              v-if="showforcedCancell"
              v-show="scope.row.status == 2"
              @click="forcedCancell(scope.row.id)"
              >强制取消
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="display: flex; justify-content: right">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <!--    上传学生照片-->
    <el-dialog
      title="上传学生照片"
      :visible.sync="dlgImportVisible"
      width="500px"
      center="center"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        :rules="importRules"
        :model="fileForm"
        ref="fileForm"
        label-width="100px"
        @submit.native.prevent
      >
        <el-form-item label="重传原因：" prop="mode" v-show="uploadImgType == 2">
          <el-radio-group v-model="fileForm.type">
            <el-radio :label="1">图片处理</el-radio>
            <el-radio :label="2">增值服务</el-radio>
            <el-radio :label="3">勘误</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="上传照片：" prop="file" style="margin-bottom: 0">
          <el-row class="photo-box">
            <el-col :span="9" style="margin-right: 20px" v-show="uploadImgType == 2">
              <div class="item">
                <el-image :src="photoImg" class="photo">
                  <div slot="placeholder" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
                <div class="desc">原照片</div>
              </div>
            </el-col>
            <el-col :span="9">
              <div class="item">
                <el-upload
                  class="upload-demo"
                  ref="uploadImport"
                  :multiple="false"
                  action=""
                  :limit="1"
                  :show-file-list="false"
                  :on-change="handleChange"
                  :file-list="fileList"
                  accept="image/jpeg,image/png"
                  list-type="picture"
                  :auto-upload="false"
                >
                  <el-image :src="uploadImg" class="photo">
                    <div slot="placeholder" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                </el-upload>
                <div class="desc">
                  {{ uploadImgType == 2 ? "重传后的照片" : "上传照片" }}
                </div>
              </div>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="人像识别：" prop="mode">
          <el-radio-group v-model="fileForm.isFaceDetect">
            <el-radio :label="1">开启</el-radio>
            <el-radio :label="0">不启用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="日志" prop="importMsg" v-if="importMsg">
          <el-alert
            :visible.sync="msgVisible"
            v-model="importMsg"
            :title="importMsg"
            show-icon
            :type="importSatus"
          >
          </el-alert>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
        style="margin: 0px 0 10px 0; text-align: center"
      >
        <el-button @click="dlgImportVisible = false">关 闭</el-button>
        <el-button type="primary" :loading="uploadLoading" @click="submitUpload"
          >确认更新</el-button
        >
      </div>
    </el-dialog>

    <!-- 订单日志 -->
    <el-dialog
      title="订单日志信息"
      :visible.sync="showOrderView"
      width="900px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-table :data="orderLogsList" stripe border height="400" style="width: 100%">
        <el-table-column prop="username" label="操作人" width="80"></el-table-column>
        <el-table-column prop="title" label="状态" width="140"></el-table-column>
        <el-table-column
          prop="createTime"
          label="执行时间"
          :formatter="columnDateFormat"
          width="160"
        ></el-table-column>
        <el-table-column prop="note" label="日志"> </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog
      title="订单支付日志列表"
      :visible.sync="orderLogsVisible"
      width="900px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-table :data="OrderFlowList" stripe border height="400" style="width: 100%">
        <el-table-column prop="orderNo" label="订单号"></el-table-column>
        <el-table-column prop="payId" label="工行订单号"></el-table-column>
        <el-table-column prop="status" label="订单状态">
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.status === 0"
              type="warning"
              effect="plain"
              size="small"
              >下单</el-tag
            >
            <el-tag
              v-else-if="scope.row.status === 1"
              type="success"
              effect="plain"
              size="small"
              >预付款
            </el-tag>
            <el-tag v-else-if="scope.row.status === 2" type="" effect="plain" size="small"
              >完成</el-tag
            >
            <el-tag v-else type="danger" effect="plain" size="small">取消</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="isSuccess" label="支付状态">
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.isSuccess === 1"
              type="success"
              effect="plain"
              size="small"
              >成功</el-tag
            >
            <el-tag v-else type="danger" effect="plain" size="small">失败</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="isSuccess" label="支付类型">
          <template slot-scope="scope">
            <span v-if="scope.row.payType === 0">线下支付</span>
            <span v-if="scope.row.payType === 1">E支付</span>
            <span v-if="scope.row.payType === 2">备用支付</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="workDatetime"
          label="执行时间"
          :formatter="columnDateFormat"
          width="160"
        ></el-table-column>
        <el-table-column prop="log" label="日志"> </el-table-column>
      </el-table>
    </el-dialog>
    <!-- 强制取消 -->
    <el-dialog
      title="强制取消"
      :visible.sync="ViewforcedVisible"
      width="600px"
      center="center"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-input
        :rows="5"
        type="textarea"
        v-model="forceCancelReason"
        placeholder="请输入取消原因"
      ></el-input>
      <div
        slot="footer"
        class="dialog-footer"
        style="margin: 0px 0 10px 0; text-align: center"
      >
        <el-button @click="forceddefine" type="primary" :loading="forcedLoading"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <orderinfo ref="orderInfoFrom" />
  </div>
</template>

<script>
import { getSchoolFiller } from "@/api/school";
import { dateFormat } from "@/utils/date";
import orderinfo from "@/components/orderinfo.vue";

import {
  queryOrder,
  deleteOrder,
  uploadPhoto,
  reUploadPhoto,
  cencelPay,
  getOrdersLogs,
  forceCancelPay,
} from "@/api/order";
import { queryPageButton } from "@/api/permission";
import { getToken } from "@/utils/auth";
import axios from "axios";
import { formatPh, formatName, formatIdCard } from "@/utils/validate";
// import { remoteLoadScript } from "@/utils/remoteLoad";
export default {
  name: "OrderMgt",
  components: {
    orderinfo, //查看详情
  },
  data() {
    return {
      isPhoto: false,
      searchData: {
        orderNo: "",
        studentName: "",
        schoolId: "",
        taskTitle: "",
        shotPrice: "",
        photoStatus: null,
        status: null,
        collectStatus: null,
        shotTypeCode: "0",
        idNumber: "",
        cjmn: "",
        studentCode: "",
      },

      orderList: [],
      tableLoading: false,
      tableSelection: [],
      currentPage: 1,
      pageSize: 20,
      total: 0,

      OrderFlowList: [],
      orderLogsVisible: false,
      orderFlowByorderNo: false,
      showOrderLogXp: false,

      dialogType: 1,
      orderForm: {},
      orderStatusForm: {},
      confirmLoading: false,
      photoImg: "../temp/none_img.jpg",
      uploadImg: "../temp/none_img.jpg",
      uploadImgType: 1,
      status: null,
      statusList: [
        {
          value: 0,
          label: "下单",
        },
        {
          value: 1,
          label: "预付款",
        },
        {
          value: 2,
          label: "完成",
        },
        {
          value: 3,
          label: "取消付款",
        },
        {
          value: 4,
          label: "支付中",
        },
      ],
      photoStatusOptions: [
        {
          value: "0",
          label: "待上传",
        },
        {
          value: "1",
          label: "已上传",
        },
      ],
      collectStatusOptions: [
        {
          value: "0",
          label: "未采集",
        },
        {
          value: "1",
          label: "已采集",
        },
      ],
      statusOptions: [
        {
          value: "0",
          label: "下单",
        },
        {
          value: "1",
          label: "预付款",
        },
        {
          value: "2",
          label: "完成",
        },
        {
          value: "3",
          label: "取消",
        },
      ],
      //学校
      schools: [],
      value: [],
      allSchools: [],
      loading: false,

      //批量导入
      orderId: "",
      msgVisible: false,
      importSatus: "info",
      importMsg: "",
      dlgImportVisible: false,
      uploadLoading: false,
      isReImport: false,
      fileForm: {
        type: 1,
        isFaceDetect: 1,
        name: "",
        file: null,
      },
      hasFile: false,
      fileList: [],
      importRules: {
        file: [{ required: true, message: "请选择上传文件", trigger: "blur" }],
      },
      //强制取消订单
      forcedID: "",
      ViewforcedVisible: false,
      forceCancelReason: "", //取消原因
      forcedLoading: false,

      //按钮权限
      searchButton: false,
      viewButton: false,
      uploadButton: false,
      cencelButton: false,
      reUploadButton: false,
      showOrderView: false,
      showforcedCancell: false, //强制按钮
      orderLogsList: [],
    };
  },
  created() {
    this.queryButton();
    this.query();
    this.querySchools();
  },
  methods: {
    changeRowBgColorByIsPay({ row, rowIndex }) {
      if (row.status === 1) {
        return { color: "#0bc58d" };
      }
      if (row.status === 0) {
        return { color: "#ffb800" };
      }
      if (row.status === 2) {
        return { color: "#F7819F" };
      }
      if (row.status === 3) {
        return { color: "#585858" };
      }
    },

    queryButton() {
      const data = 4;
      queryPageButton(data).then((resp) => {
        for (const button of resp.data) {
          if (button.code === "search") {
            this.searchButton = true;
          }
          if (button.code === "view") {
            this.viewButton = true;
          }
          if (button.code === "upload") {
            this.uploadButton = true;
          }
          if (button.code === "cencel") {
            this.cencelButton = true;
          }
          if (button.code === "reUpload") {
            this.reUploadButton = true;
          }
          if (button.code === "orderFlowByorderNo") {
            this.orderFlowByorderNo = true;
          }
          if (button.code === "showOrderLogXp") {
            this.showOrderLogXp = true;
          }
          if (button.code === "showforcedCancell") {
            this.showforcedCancell = true;
          }
        }
      });
    },
    querySchools() {
      getSchoolFiller().then((resp) => {
        this.allSchools = resp.data;
        this.schools = resp.data;
      });
    },
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.schools = this.allSchools.filter((item) => {
            if (typeof item != undefined) {
              return item.schoolFilterName.indexOf(query) > -1;
            }
          });
        }, 200);
      } else {
        this.schools = [];
      }
    },
    // 强制取消订单
    forcedCancell(id) {
      this.forcedID = id;
      this.forceCancelReason = "";
      this.ViewforcedVisible = true;
    },
    //确定取消
    forceddefine() {
      if (!this.forceCancelReason) {
        this.$notify({
          title: "警告",
          message: "请输入取消原因",
          type: "warning",
        });
        return;
      }
      this.forcedLoading = true;
      let postData = {
        id: this.forcedID,
        forceCancelReason: this.forceCancelReason,
      };
      forceCancelPay(postData)
        .then((res) => {
          if (res.code == 0) {
            this.ViewforcedVisible = false;
            this.$notify({
              title: "温馨提示",
              message: res.message,
              type: "success",
            });
          } else {
            this.ViewforcedVisible = false;
            this.query();
            this.forcedLoading = false;
            this.$notify({
              title: "警告",
              message: res.message,
              type: "warning",
            });
          }
        })
        .catch((err) => {
          {
            this.query();
            this.forcedLoading = false;
            this.ViewforcedVisible = false;
            this.$notify({
              title: "警告",
              message: "未知错误",
              type: "warning",
            });
          }
        });
    },
    /**
     * 查询订单日志
     */
    showOrderLog(data) {
      let that = this;
      getOrdersLogs(data.id).then((res) => {
        if (res.code == 0) {
          that.orderLogsList = res.data;
          that.showOrderView = true;
        }
      });
    },
    /**
     * 查询订单支付日志
     */
    handleOrderLogs(data) {
      this.orderLogsVisible = true;
      getOrderFlowByorderNo(data.orderNo)
        .then((resp) => {
          if (resp.data) {
            this.OrderFlowList = resp.data;
          }
        })
        .catch((error) => {});
    },
    query() {
      this.tableLoading = true;
      queryOrder(this.currentPage, this.pageSize, this.searchData).then((resp) => {
        this.orderList = resp.data.content;
        this.total = resp.data.totalElements;
        this.tableLoading = false;
      });
    },
    handleSearch() {
      this.currentPage = 1;
      this.query();
    },
    resetSearch() {
      this.searchData = {};
      this.currentPage = 1;
      this.query();
    },
    handleImport(id) {
      if (this.$refs.fileForm != undefined) {
        //重置表单信息
        this.$refs.fileForm.resetFields();
      }
      this.orderId = id;
      this.isReImport = false;
      this.dlgImportVisible = true;
      this.fileList = [];
      this.importMsg = "";
      this.msgVisible = false;
      this.importSatus = "info";
      this.uploadLoading = false;

      this.uploadImgType = 1;
      this.uploadImg = "../temp/none_img.jpg";
    },
    handleReImport(id, resp) {
      if (this.$refs.fileForm != undefined) {
        //重置表单信息
        this.$refs.fileForm.resetFields();
      }
      this.orderId = id;
      this.isReImport = true;
      this.dlgImportVisible = true;
      this.fileList = [];
      this.importMsg = "";
      this.msgVisible = false;
      this.importSatus = "info";
      this.uploadLoading = false;
      this.uploadImg = "../temp/none_img.jpg";
      this.uploadImgType = 2;
      this.photoImg = "../temp/none_img.jpg?timestamp=" + new Date().getTime();
      this.isPhoto = false;
      this.getPhoto(resp.pathCollectedPhoto);
    },
    handleCencelPay(id) {
      this.$confirm("是否确认取消订单并退款吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const data = { id: id };
          cencelPay(data)
            .then((resp) => {
              if (resp.data.code == 0) {
                this.$notify({
                  title: "取消订单成功",
                  message: resp.message,
                  type: "success",
                });
              } else {
                this.$notify({
                  title: "取消订单失败",
                  message: resp.data.msg,
                  type: "info",
                });
              }
              this.currentPage = 1;
              this.query();
            })
            .catch((error) => {});
        })
        .catch(() => {});
    },
    handleDelete() {
      if (this.tableSelection === null || this.tableSelection.length === 0) {
        this.$notify({
          title: "警告",
          message: "选择的项不能为空",
          type: "warning",
        });
        return;
      }

      this.$confirm("此操作将删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const data = [];
          for (const item of this.tableSelection) {
            data.push(item.id);
          }
          deleteOrder(data)
            .then((resp) => {
              this.$notify({
                title: "删除成功",
                message: resp.message,
                type: "success",
              });
              this.currentPage = 1;
              this.query();
            })
            .catch((error) => {});
        })
        .catch(() => {});
    },

    handleView(id) {
      if (this.$refs.orderInfoFrom != undefined) {
        this.$refs.orderInfoFrom.initData(id, "xp");
      }
    },
    //上传学生照片
    submitUpload() {
      this.uploadLoading = true;
      //this.$refs.upload.submit();
      if (this.$refs.upload) {
        this.$refs.upload.clearFiles(); // 清除上传的文件
      }
      this.fileList = [];

      this.$refs.fileForm.validate((valid) => {
        if (valid) {
          let dataPar = this.fileForm;
          let fdParams = new FormData();
          fdParams.append("id", this.orderId);
          fdParams.append("isFaceDetect", dataPar.isFaceDetect);
          fdParams.append("file", dataPar.file.raw);
          fdParams.append("type", dataPar.type);
          if (this.isReImport) {
            reUploadPhoto(fdParams).then((resp) => {
              if (resp.data.code == 0) {
                this.importMsg = resp.data.msg;
                this.importSatus = "success";
                this.dlgImportVisible = false;
                this.$notify({
                  title: "上传照片成功！",
                  message: resp.message,
                  type: "success",
                });
              } else {
                this.importMsg = resp.data.msg;
                this.importSatus = "error";
              }
              this.uploadLoading = false;
              this.msgVisible = false;
              this.query();
            });
          } else {
            uploadPhoto(fdParams).then((resp) => {
              if (resp.data.code == 0) {
                this.importMsg = resp.data.msg;
                this.importSatus = "success";
                this.dlgImportVisible = false;
                this.$notify({
                  title: "上传照片成功！",
                  message: resp.message,
                  type: "success",
                });
              } else {
                this.importMsg = resp.data.msg;
                this.importSatus = "error";
              }
              this.uploadLoading = false;
              this.msgVisible = false;
              this.query();
            });
          }
        }
      });
    },
    getPhoto(fileid) {
      const url =
        //window._config["baseUrl"] +
        "/api/csias/file/download/" + fileid + "?timestamp=" + new Date().getTime();
      axios({
        method: "GET",
        url: url,
        headers: {
          Authorization: "Bearer " + getToken(),
        },
        responseType: "blob",
        timeout: 180000,
      }).then(
        (response) => {
          if (response.status === 200 && response.data) {
            const imgContent = response.data;
            this.photoImg = window.URL.createObjectURL(imgContent);
            this.isPhoto = true;
            // this.init(this.$refs.wrapper);
          }
        },
        (err) => {
          this.$notify.error({
            title: "错误",
            message: "照片加载失败，未查询到照片信息",
          });
        }
      );
    },
    handleRemove(file, fileList) {
      if (!fileList.length) {
        this.hasFile = false;
      }
      this.fileForm.file = null;
    },
    handlePreview(file) {
      //预览
      console.log(file);
    },
    handleChange(file, fileList) {
      if (fileList.length >= 2) {
        return;
      }
      if (fileList.length === 1) {
        this.hasFile = true;
      }
      this.uploadImg = file.url;
      this.fileForm.file = file;
    },

    handleSizeChange(val) {
      this.pageSize = val;
      this.query();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.query();
    },
    columnDateFormat(row, column, cellValue, index) {
      const dateValue = row[column.property];
      if (dateValue != null) {
        return dateFormat("YYYY-mm-dd HH:MM:SS", new Date(dateValue));
      }
    },
    formDateFormat(dateValue) {
      if (dateValue != null) {
        return dateFormat("YYYY-mm-dd", new Date(dateValue));
      }
    },
    formDateTimeFormat(dateValue) {
      if (dateValue != null) {
        return dateFormat("YYYY-mm-dd HH:MM:SS", new Date(dateValue));
      }
    },
    // 姓名、手机号脱敏
    execDecrypt(str, mod) {
      if (!str) {
        return str;
      } else if (mod == "name") {
        return formatName(str);
      }
      if (mod == "ph") {
        return formatPh(str);
      }
      if (mod == "Idcard") {
        return formatIdCard(str);
      }
    },
    init(el) {
      this.mouseDown(el);
    },
    //pc端鼠标拖动
    mouseDown(el) {
      //来源：https://juejin.cn/post/7159027488252198926
      const _this = this;
      const $el = el;
      const slider = _this.$refs.drag;
      const rect = $el.getBoundingClientRect();
      slider.onmousedown = function (event) {
        event.preventDefault();
        document.onmousemove = function (e) {
          var sliderY = e.clientY - rect.top;
          if (sliderY < 0) {
            sliderY = 0;
          } else if (sliderY > rect.height) {
            sliderY = rect.height;
          }
          //滑块的位置
          slider.style.top = sliderY - 15 + "px";
          //进度条的长度
          //_this.plan = ((sliderY / rect.height) * 100).toFixed(0)
        };
        document.onmouseup = function () {
          document.onmousemove = null;
          document.onmousedown = null;
        };
      };
    },
  },
  mounted() {
    // remoteLoadScript("/drag.js");
    //drag
    // this.init(this.$refs.wrapper);
  },
};
</script>

<style>
.tip {
  padding: 8px 16px;
  background-color: #f9f9f9;
  border-radius: 4px;
  border-left: 5px solid #446cf3;
  margin: 5px 0;
  line-height: 20px;
}

.rowGray .cell,
.rowBlank .cell {
  padding: 0 5px;
  border: #e8e8e8 1px solid;
  height: 40px;
  line-height: 40px;
  vertical-align: bottom;
}

.rowGray .cell {
  background: #f2f2f2;
}

.divice {
  width: 1200px;
}

.divice .col-1eft {
  float: left;
  width: 900px;
  height: 160px;
}

.divice .col-right {
  float: left;
  margin-left: 5px;
  width: 200px;
  height: 150px;
}

.clear-float {
  clear: both;
}
.image-box {
  width: 120px;
  height: 160px;
  margin: auto;
  position: relative;
}
.image-box.line .photo:before {
  content: " ";
  width: 1px;
  height: 100%;
  position: absolute;
  background: #e13333;
  left: 50%;
}
.image-box.line .drag-list {
  position: absolute;
  width: 100%;
  height: 30px;
  bottom: 30px;
  cursor: all-scroll;
}
.image-box.line .drag-list::before {
  content: " ";
  width: 100%;
  height: 1px;
  position: absolute;
  background: #e13333;
  top: 50%;
  left: 0;
}
.photo {
  width: 120px;
  height: 160px;
  position: relative;
  vertical-align: top;
}
.photo-box .item {
  text-align: center;
}
.photo-box .item .desc {
  line-height: 30px;
  text-align: center;
}
.photo-box .item .photo {
  margin-right: 0;
}
</style>
